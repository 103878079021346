<template>

<div class="clashes" v-if="clashes.length">

	<p v-if="isEvent">This event clashes with the following scheduled events you have. Do you want to cancel them and proceed with joining this one?</p>
	<p v-if="isSlot">This clashes with the following volunteer assignments you have. Do you want to cancel them and proceed with this one?</p>

	<div class="clashes-item" v-for="(clash, index) in clashes" :key="index">

		<template v-if="isEvent">

			<div class="clashes-item-name">{{ clash.name }}</div>
			<div class="clashes-item-time">{{ clash.date | formatDate('dddd') }}, {{ clash.date | formatDate($hourFormat) }} - {{ (clash.date + clash.duration) | formatDate($hourFormat) }}</div>

		</template>

		<template v-if="isSlot">

			<div class="clashes-item-name">{{ clash.role.name }}</div>
			<div class="clashes-item-time">{{ clash.slot.name }}</div>

		</template>

	</div>

</div>

</template>

<script>

export default {

	props: ['clashes', 'type'],

	computed: {

		isEvent: function() {

			return !this.isSlot

		},

		isSlot: function() {

			return this.type === 'slot'

		}

	}

}

</script>

<style scoped>

.clashes {
	font-size: 16px;
	color: #707070;
	line-height: 20px;
}

.clashes-item {
	border: 1px solid #ddd;
	background-color: #fff;
    border-radius: 10px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
}

.clashes-item-name {
	font-size: 16px;
	font-weight: 500;
	color: #000;
}

.clashes-item-time {
	font-size: 16px;
	font-weight: 300;
	color: #000;
}

</style>
